import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; 

import BoxOne from './brutonetto/boxone';
import BoxTwoThree from './brutonetto/boxtwothree';
import General from './brutonetto/general';
import Output from './brutonetto/output';
import Start from './brutonetto/start';
import TaxCredit from './brutonetto/taxcredit';

export default withRouter(class extends Component {
  getSubPage() {
    const page = typeof this.props.match.params.page === 'undefined' ? '' : this.props.match.params.page;
    const pageProps = {
      goNext: this.goNext.bind(this),
      goPrevious: this.goPrevious.bind(this),
      onTabClick: this.onTabClick.bind(this)
    };

    switch (page.toLowerCase()) {
      case "general": return <General {...pageProps} />;
      case "boxone": return <BoxOne {...pageProps} />;
      case "boxtwothree": return <BoxTwoThree {...pageProps} />;
      case "taxcredit": return <TaxCredit {...pageProps} />;
      case "output": return <Output {...pageProps} />;
      default: return <Start {...pageProps} />;
    }
  }


  onTabClick(clickedTabUrl) {
    this.goTo(clickedTabUrl);
  }

  goNext(currentTab) {
    let locations = ['start', 'general', 'boxone', 'boxtwothree', 'taxcredit', 'output'];
    let nextLocation = locations[0];
    let index = locations.indexOf(currentTab);
    if (index + 1 < locations.length) {
      nextLocation = locations[index + 1];
    }
    this.goTo(nextLocation);
  }

  goPrevious(currentTab) {
    let locations = ['start', 'general', 'boxone', 'boxtwothree', 'taxcredit', 'output'];
    let nextLocation = locations[0];
    let index = locations.indexOf(currentTab);
    if (index > 0) {
      nextLocation = locations[index - 1];
    }
    this.goTo(nextLocation);
  }

  goTo(page) {
    this.props.history.replace(this.props.match.path.replace(':page', page));
    //this.setState({});
  }

  render() {
    return (
      <>
        <section className="hero is-danger">
          <div className="hero-body">
            <div className="container">
              <h2 className="title">Bruto Netto Calculator</h2>
            </div>
          </div>
        </section>
        <section className="section">
          {this.getSubPage()}
        </section>
      </>
    );
  }
});
