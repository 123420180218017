import * as React from 'react';
import { observer } from 'mobx-react';
import FormStaticAddon from './FormStaticAddon';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';

import * as moment from 'moment';


class FormCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      datePickerFocused: false
    };
  }

  getDatePickerHtml() {
    const { field, minDate, maxDate, minDays, numberOfMonths, endField } = this.props;
    if (!this.state.datePickerFocused) {
      return '';
    }

    return (
      <DayPickerRangeController
        hideKeyboardShortcutsPanel={true}
        minimumNights={minDays}
        withPortal={true}
        minDate={typeof minDate === 'undefined' ? null : moment(minDate, "YYYY-MM-DD")}
        maxDate={typeof maxDate === 'undefined' ? null : moment(maxDate, "YYYY-MM-DD")}
        enableOutsideDays={false}
        numberOfMonths={numberOfMonths}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onDatesChange={({ startDate, endDate }) => {
          if (minDays === 0) {
            // endDate is sometimes null
            if (endDate === null) {
              endDate = startDate;
            }
            field.inputProps.onChange({ target: { value: endDate === null ? '' : endDate.format('YYYY-MM-DD') } });
            this.setState({ startDate: endDate, endDate });
          } else {
            field.inputProps.onChange({ target: { value: startDate === null ? '' : startDate.format('YYYY-MM-DD') } });
            endField.inputProps.onChange({ target: { value: endDate === null ? '' : endDate.format('YYYY-MM-DD') } });
            this.setState({ startDate, endDate });
          }
        }}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => {
          this.setState({
            focusedInput: minDays > 0 ? focusedInput === null ? "startDate" : focusedInput : 'endDate'
          });
        }}
        onOutsideClick={() => this.setState({ datePickerFocused: false })} />
    );
  }

  getInput(field) {
    const { disabled, minDays } = this.props;
    const errorHtml = this.getFieldError(field);
    return (
      <>
        <input type="text" className={`input${errorHtml.length > 0 ? ' is-danger' : ''}`} {...field.inputProps} readOnly={true} disabled={disabled} onClick={() => this.setState(prevState => ({
          datePickerFocused: !prevState.datePickerFocused,
          focusedInput: minDays === 0 ? "endDate" : "startDate"
        }))} />
      </>
    );
  }

  getFieldError(field) {
    const invalid = typeof field.error !== 'undefined' && field.error.length > 0;
    if (!invalid) {
      return '';
    }

    return (
      <p className="help is-danger">{field.error}</p>
    );
  }

  horizontalOutput() {
    const { addonLeft, addonRight } = this.props;
    const fields = this.getFields();
    return (
      <>
        {this.getDatePickerHtml()}
        <div className="field-body">
          {fields.map(field => {
            return (
              <div className="field is-expanded has-addons" key={field.name}>
                {typeof addonLeft === 'undefined' ? '' : <FormStaticAddon>{addonLeft}</FormStaticAddon>}

                <div className={`control is-expanded`}>
                  {this.getInput(field)}
                  {this.getFieldError(field)}
                </div>

                {typeof addonRight === 'undefined' ? '' : <FormStaticAddon>{addonRight}</FormStaticAddon>} 
              </div>
            );
          })}
        </div>
      </>
    );
  }
  getFields() {
    const { field, endField } = this.props;
    const items = [field];
    if (typeof endField !== 'undefined') {
      items.push(endField);
    }
    return items;
  }

  regularOutput() {
    const { addonLeft, addonRight } = this.props;
    const fields = this.getFields();

    return (
      <>
        {this.getDatePickerHtml()}
        {fields.map(field => {
          return (
            <div className="field" key={field.name}>
              <div className="field has-addons">
                {typeof addonLeft === 'undefined' ? '' : <FormStaticAddon>{addonLeft}</FormStaticAddon>}
                <div className="control is-expanded">{this.getInput(field)}</div>
                {typeof addonRight === 'undefined' ? '' : <FormStaticAddon>{addonRight}</FormStaticAddon>} 
              </div>
              {this.getFieldError(field)}
            </div>
          );
        })}
      </>
    );
  }

  render() {
    // TODO: enable localization
    const locale = 'nl';
    import('moment/locale/' + locale); // Import as a function allows for dynamic imports
    moment.locale(locale);

    const { horizontal } = this.props;
    if (typeof horizontal !== 'undefined' && horizontal) {
      return this.horizontalOutput();
    }

    return this.regularOutput();
  }
}

FormCalendar.defaultProps = {
  minDays: 0,
  numberOfMonths: 2
};

export default observer(FormCalendar);
