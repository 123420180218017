import React, { Component } from 'react';
import i18n from '../i18n';
import Icon from './Icon';

export default class extends Component {
  static linkActiveClassName = 'is-active';

  constructor(props) {
    super(props);
    this.state = { language: '', loadedLanguages: [] }

    this.onTranslationLoaded = this.onTranslationLoaded.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    console.log('i18next.languages', i18n.languages)
  }

  componentDidMount() {
    i18n.on('loaded', this.onTranslationLoaded);
    i18n.on('languageChanged', this.onLanguageChanged);
  }

  componentWillUnmount() {
    i18n.off('loaded', this.onTranslationLoaded);
    i18n.off('languageChanged', this.onLanguageChanged);
  }

  onTranslationLoaded(loaded) {
    this.setState({ loadedLanguages: loaded }); 
  }

  onLanguageChanged(lng) { 
    this.setState({ language: lng });
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  render() {
    console.log('i18next.languages', i18n.languages)
    return (
      <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link" href="#language-toggle" onClick={(e) => e.preventDefault()}>
          <span className="icon">
            <Icon fa="globe" category="fas" size="lg" />
          </span>
          <span>{this.state.language.toUpperCase()}</span>
        </a>
        <div className="navbar-dropdown is-boxed">
          {Object.keys(this.state.loadedLanguages).map(lang => {
            return (
              <a className="navbar-item" href={`#lang-switch-${lang}`} onClick={(e) => { e.preventDefault(); this.changeLanguage(lang) }} key={lang}>
                {lang.toUpperCase()}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
} 
