import * as React from 'react';
import { observer } from 'mobx-react';

class FormSelect extends React.Component {
  render() {
    const { field, horizontal, invalid, errorMsg, children, disabled } = this.props;
    if (horizontal) {
      return (
        <div className="field-body">
          <div className="field is-expanded">
            <p className="select is-fullwidth">
              <select className={`input${invalid ? ' is-danger' : ''}`} {...field.inputProps} disabled={disabled}>
                {children}
              </select>
            </p>
            {!invalid ? '' :
              <p className="help is-danger">{errorMsg}</p>
            }
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="select">
          <select className={`input${invalid ? ' is-danger' : ''}`} disabled={disabled}>
            {children}
          </select>
          {!invalid ? '' :
            <p className="help is-danger">{errorMsg}</p>
          }
        </div>
      </>
    );
  }
}

export default observer(FormSelect);
