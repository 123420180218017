import { types } from "mobx-state-tree";

export default types.model({
  inwonerNL: types.optional(types.string, 'correct'),
  inwonerEER: types.optional(types.string, 'correct'),
  brutoOfNetto: types.optional(types.string, 'bruto'),
  jaarOfMaand: types.optional(types.string, 'jaar'),
  dertigProcentRegeling: types.optional(types.string, 'incorrect'),
  dertigProcentAfwijkendBedrag: types.optional(types.number, 0),
  socialeVerzekering: types.optional(types.string, 'nederland'),
  beginPeriode: types.optional(types.string, '2018-01-01'),
  eindPeriode: types.optional(types.string, '2018-12-31'),
  geboortedatum: types.optional(types.string, '1980-01-01')
});
