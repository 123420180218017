import { types } from "mobx-state-tree";
import BrutoNetto from './brutonetto/BrutoNetto';
import TransitieVergoeding from './transitievergoeding/TransitieVergoeding';
import BelastingRente from './belastingrente/BelastingRente';

export default types.model({
  brutonetto: types.optional(BrutoNetto, {}),
  transitievergoeding: types.optional(TransitieVergoeding, {}),
  belastingrente: types.optional(BelastingRente, {})
});
