import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from "mobx-react";
import CalculatorTabMenu from '../../components/tabmenu/CalculatorTabMenu';

export default withRouter(inject("shop")(observer(
  class extends Component {

    async onTabClick(clickedTabUrl) {
      this.props.onTabClick(clickedTabUrl);
    }

    render() {
      const lang = 'nl';
      const generalCoverage = {
        nederland: {
          nl: 'Sociaal verzekerd in Nederland', en: 'Exclusively covered by the Dutch social security system'
        },
        duitsland: {
          nl: 'Sociaal verzekerd in Duitsland', en: 'Exclusively covered by the German social security system'
        },
        belgie: {
          nl: 'Sociaal verzekerd in België', en: 'Exclusively covered by the Belgian social security system'
        },
        geenpremie: {
          nl: 'Niet sociaal verzekerd in Nederland', en: 'Not covered by the Dutch social security system'
        },
        andersv: {
          nl: 'Sociaal verzekerd in ander land', en: 'Exclusively covered by the Foreign social security system'
        }
      };
      //const salaryNLFiscaal = algemeen.brutoOfNetto === 'bruto'
      //  ? algemeen.jaarOfMaand === 'jaar'
      //    ? boxeen.arbeidsinkomen
      //    : parseFloat(boxeen.arbeidsinkomen) / 12
      //  : parseFloat();
      const { start, algemeen } = this.props.shop.brutonetto;
      return (
        <div className="container">
          <CalculatorTabMenu onTabClick={this.props.onTabClick} />
          <section className="section">
            <div className="box">
              <h2 className="title is-size-3">Veronderstelling</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <th>Werkgever</th>
                    <td>{start.werkgever}</td>
                  </tr>
                  <tr>
                    <th>Werknemer</th>
                    <td>{start.werknemer}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {
                        algemeen.brutoOfNetto === 'bruto'
                          ? lang === 'nl'
                            ? 'Indicatieve 2019 Bruto-Netto berekening'
                            : 'Indicative 2019 Gross-Net calculation'
                          : lang === 'nl'
                            ? 'Indicatieve 2019 Netto-Bruto berekening'
                            : 'Indicative 2019 Net-Gross calculation'
                      }</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      {
                        algemeen.inwonerNL === 'correct'
                          ? lang === 'nl'
                            ? 'Inwoner van Nederland'
                            : 'Resident of the Netherlands'
                          : lang === 'nl'
                            ? 'Niet-inwoner van Nederland'
                            : 'Non-resident of the Netherlands'
                      }</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{ generalCoverage[algemeen.socialeVerzekering][lang] }</td>
                  </tr>
                  <tr>
                    <td> colspan={2}>{
                      algemeen.dertigProcentRegeling === "correct"
                        ? lang === 'nl'
                          ? `30% regeling is van toepassing.${algemeen.brutoOfNetto === 'bruto' ? '' : ' Het netto afgesproken loon is gebruteerd.'}{J16 === 0 ? '' : ' De werknemer heeft een brutoloon van FORMAT(L136)". De 30% vergoeding is berekend over het loon voor de loonheffing voorzover niet ter heffing aan het buitenland toegewezen. Als gevolg van de toepassing van de 30% regeling is het oorspronkelijk overeengekomen brutoloon arbeidsrechtelijk verlaagd.'}`
                          : `30% ruling is applicable.${algemeen.brutoOfNetto === 'bruto' ? '' : ' The net agreed salary has been grossed-up.'}{J16 === 0 ? '' : ' The employee is entitled to a Dutch gross salary of FORMAT(L136)". The 30% reimbursement is calculated on the effective Dutch taxable wage. As a result of the 30% ruling, the original agreed salary is reduced for tax purposes.'}`
                    : lang === 'nl' 
                      ? "30% regeling is niet van toepassing"
                      : "30% ruling is not applicable"
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <p className="is-size-7">Deze tool is uitsluitend opgesteld als hulpmiddel. Het dient geen specifiek doel en dient niet te worden geïnterpreteerd als professioneel advies. Gebruik ervan is voor eigen rekening en risico. U dient niet te handelen op basis van de in deze tool vervatte informatie zonder nader professioneel advies te hebben ingewonnen. Er wordt geen enkele expliciete of impliciete verklaring verstrekt of garantie geboden over de juistheid of volledigheid van de in deze tool vervatte informatie, en voor zover toegestaan krachtens de wet, aanvaarden PwC, aan haar gelieerde ondernemingen, medewerkers en vertegenwoordigers geen enkele aansprakelijkheid, en wijzen zij iedere verantwoordelijkheid af, voor de gevolgen van enige handeling dan wel omissie door hetzij uzelf hetzij enige andere persoon op basis van de in deze tool vervatte informatie of voor enig besluit waaraan die informatie ten grondslag ligt. PwC heeft de eigendomsrechten op deze tool. Gebruiker is het volgende niet toegestaan, noch mag gebruiker een derde machtigen het volgende te doen met betrekking tot deze tool en/of de inhoud daarvan: (a) kopiëren of verspreiden, (b) aanpassen of een afgeleide creëren, (c) de broncode afleiden, veranderen, bewerken etc. (d) distribueren, exploiteren, overdragen, sublicenseren van deze tool of daaraan gerelateerde rechten.</p>
        </div>
      );
    }
  }
)));
