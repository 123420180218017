import { types } from "mobx-state-tree";

export default types.model({
  brutoSalaris: types.optional(types.number, 0),
  vasteBonus: types.optional(types.number, 0),
  overwerkVergoeding: types.optional(types.number, 0),
  ploegenToeslag: types.optional(types.number, 0),
  vasteDertiendeMaand: types.optional(types.number, 0),
  vakantietoeslag: types.optional(types.number, 8),
  variabelLoon: types.optional(types.number, 0)
});
