import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import LanguageSwitch from './LanguageSwitch';

export class NavMenu extends Component { 
  static linkActiveClassName = 'is-active';

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <div className="container">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                <img src="https://upload.wikimedia.org/wikipedia/commons/0/05/PricewaterhouseCoopers_Logo.svg" width="69" height="52" alt="logo" />
              </Link>

              <span role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={() => { this.toggleNavbar() }}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </span>
            </div>

            <div className={`navbar-menu ${this.state.collapsed ? 'is-active' : ''}`} >
              <div className="navbar-start">
                <Link exact to="/" className="navbar-item" activeClassName={this.linkActiveClassName}>Home</Link>
                <Link exact to="/brutonetto" className="navbar-item" activeClassName={this.linkActiveClassName}>BrutoNetto Calculator</Link>
                <Link exact to="/transitievergoeding" className="navbar-item" activeClassName={this.linkActiveClassName}>Transitie Vergoeding Calculator</Link>
                <Link exact to="/belastingrente" className="navbar-item" activeClassName={this.linkActiveClassName}>Belasting Rente Calculator</Link>
                {/*<Link to="/fetch-data" className="navbar-item" activeClassName={this.linkActiveClassName}>Fetch data</Link> */}
              </div>

              <div className="navbar-end">
                <LanguageSwitch />
                <LoginMenu />
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
