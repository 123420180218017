import { types } from "mobx-state-tree";

import Start from './start';
import Algemeen from './algemeen';
import Boxeen from './boxeen';
import Boxtweedrie from './boxtweedrie';
import Heffingskortingen from './heffingskortingen';


export default types
  .model({
    start: types.optional(Start, {}),
    algemeen: types.optional(Algemeen, {}),
    boxeen: types.optional(Boxeen, {}),
    boxtweedrie: types.optional(Boxtweedrie, {}),
    heffingskortingen: types.optional(Heffingskortingen, {}),
  })
  .views(self => ({
    get omrekensheet() {
      const invoer = {
        B3: self.boxeen.arbeidsinkomen,
        B4: self.boxeen.extraterritorialeVergoedingen,
        B5: self.boxeen.extraInvoerveld1,
        B6: self.boxeen.extraInvoerveld2,
        B7: self.boxeen.buitenlandsArbeidsinkomen,
        B8: self.boxeen.buitenlandseBelasting,
        B10: self.boxeen.onbelasteVergoedingen,
        B11: self.boxeen.overigeAftrekposten,
        B12: self.boxeen.reiskostenvergoeding,
        B13: self.boxeen.overigBoxEenInkomen,
        B14: self.boxeen.nettoVergoeding,
        B16: self.boxeen.pensioenpremieWerknemer,
        B17: self.boxeen.franchise,
        B18: self.boxeen.pensioenpremieWerkgever,
        B20: self.boxeen.eigenBijdrage,
        B21: self.boxeen.totaleKostenWerkgever,
        B23: self.boxeen.totaleHuur,
        B24: self.boxeen.eigenBijdrageInVerbandMetHuur,
        B26: self.boxeen.nominalePremiesZiektekostenverzekering,
        B27: self.boxeen.additioneleVergoedingZiektekostenverzekering,
        B29: self.boxeen.hypotheekrente,
        B30: self.boxeen.andereAftrekposten
      };

      const isYear = self.algemeen.jaarOfMaand === 'jaar';
      const uitvoer = Object.assign({}, ...Object.keys(invoer).map(x => ({ [x.replace('B', 'C')]: isYear ? invoer[x] : invoer[x] / 12 })));

      return { ...invoer, ...uitvoer };
    },

    get netto() {
      let boxeenExtrainvoerveld1Uitbetalen = true;
      let boxeenExtrainvoerveld2Uitbetalen = true;
      let huurvergwg = true;
      let cells = {
        I5: 0,
        I6: self.omrekensheet.C7,
        I7: boxeenExtrainvoerveld1Uitbetalen ? self.omrekensheet.C5 : 0,
        I8: boxeenExtrainvoerveld2Uitbetalen ? self.omrekensheet.C6 : 0,
        I9: 0, // wtf? i9 -> m5 -> i5 -> i9
        I10: huurvergwg ? self.omrekensheet.C23 : 0,
        I11: -self.omrekensheet.C24,
        I12: 0,
        I13: 0,
        I14: 0,
        I15: 0,
        I17: 0,
        I19: 0,
        I20: 0,
        I21: 0,
        I27: 0,
        I30: 0,
        I34: 0,
        I35: 0,
        I36: 0,
        I37: 0,
        I38: 0,
        I39: 0,
        I40: 0,
        I41: 0,
        I42: 0,
        I43: 0,
        I44: 0,
        I45: 0,
        I46: 0,
        I47: 0,
        I48: 0,
        I49: 0,
        I50: 0,
        I51: 0,
        I52: 0
      };

      let K46 = 0; // TODO

      cells.I5 = cells.I46 - cells.I40 - cells.I43 - cells.K46 - cells.I30 - cells.I27 - cells.I17 - cells.I50 - cells.I15 - cells.I9 - cells.I19 - cells.I6 - cells.I7 - cells.I8 - cells.I10 - cells.I11 - cells.I12 - cells.I13 - cells.I14;

      let AlgemeenK7 = self.algemeen.inwonerNL === "incorrect";
      let Beschikking = self.boxeen.achttienProcentHuisvestingRegeling === 'correct';
      let Perc_BTL_auto_invoer = (1 - self.boxeen.buitenlandseBelasting > 0 ? self.boxeen.buitenlandseBelasting / (self.boxeen.arbeidsinkomen + self.boxeen.buitenlandseBelasting) : 0);

      let M5 = Math.min(cells.I5, 107593);

      let G5 = cells.I5;
      let G6 = AlgemeenK7 ? cells.I6 : 0;
      let G7 = self.algemeen.dertigProcentRegeling === 'correct' ? self.omrekensheet.C5 : self.omrekensheet.C5 * Perc_BTL_auto_invoer;
      let G8 = self.algemeen.dertigProcentRegeling === 'correct' ? self.omrekensheet.C6 : self.omrekensheet.C6 * Perc_BTL_auto_invoer;


      return { ...cells };
    },

    get berekening() {
      let cells = {
        I5: self.algemeen.brutoOfNetto === 'bruto' ? self.omrekensheet.C3 : self.netto.I5
      };

      return { ...cells };
    },

    get output() {
      return {};
    },

    get SVBEDtsld() {
      let cells = {
        F11: self.algemeen.jaarOfMaand === 'jaar' ? 'SV!K28' : 'SV!K28/ 12'
      };

      return { ...cells };
    },

    get gegevens() {
      let cells = {
        C120: 0,
        C111: 'SV Be - Dtsld!F17'
      };

      return { ...cells };
    }

  }))
  .actions(self => (({
  })));

// restrict functions from being exposed, use as the following:
/*
 .actions(self => {
    const test = () => {
      test2();
    };

    const test2 = () => {
      // function test2
    };

    return { test }; // only the function test is exposed
  });
*/
