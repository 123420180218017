import { types } from "mobx-state-tree";
import moment from "moment";

import Algemeen from './algemeen';
import LoonComponenten from './looncomponenten';
import Werkgever from './werkgever';

export default types
  .model({
    algemeen: types.optional(Algemeen, {}),
    looncomponenten: types.optional(LoonComponenten, {}),
    werkgever: types.optional(Werkgever, {})
  })
  .views(self => ({
    get brutoMaandSalaris() {
      return (
        (self.looncomponenten.brutoSalaris +
          self.looncomponenten.vasteBonus +
          self.looncomponenten.overwerkVergoeding +
          self.looncomponenten.ploegenToeslag +
          self.looncomponenten.vasteDertiendeMaand) *
        (1 + (self.looncomponenten.vakantietoeslag / 100)) +
        self.looncomponenten.variabelLoon
      );
    },

    get kantonRechtersFormule() {
      console.log('brutoMaandSalaris', this.brutoMaandSalaris);
      // We can change the correctiefactor if needed
      const correctieFactor = 1;
      const gewogenDienstJaren = this.getGewogenDienstjaren;

      const kantonRechtersFormule = gewogenDienstJaren * this.brutoMaandSalaris * correctieFactor;

      return Math.round(kantonRechtersFormule * 100) / 100;
    },

    get getGewogenDienstjaren() {
      const geboortedatum = moment(self.algemeen.geboortedatum);
      const datumInDienst = moment(self.algemeen.datumInDienst);
      const datumUitDienst = moment(self.algemeen.datumUitDienst);

      // If faulty input, return 0
      if (datumUitDienst.isBefore(datumInDienst)) {
        return 0;
      }

      const dienstjaren = Math.round(datumUitDienst.diff(datumInDienst, 'years', true));

      const weights = [
        { age: 35, ratio: 0.5 },
        { age: 45, ratio: 1 },
        { age: 55, ratio: 1.5 },
        { age: -1, ratio: 2 }
      ];

      const leeftijdInDienst = datumInDienst.diff(geboortedatum, 'years');

      // Disregard the weights that are not applicable at the bottom end of the list.
      const eersteSchijfIndex = weights.indexOf(weights.find(({ age }) => leeftijdInDienst < age));

      let dienstjarenPerSchijf = [0, 0, 0, 0];

      let beginDatum = datumInDienst.clone();

      for (let i = eersteSchijfIndex; i < weights.length; i++) {
        const schijf = weights[i];

        const grens = geboortedatum.clone().add(schijf.age, 'years');
        if (schijf.age !== -1 && grens.isBefore(datumUitDienst)) {
          dienstjarenPerSchijf[i] = grens.diff(beginDatum, 'years', true);
          beginDatum = grens.clone();
        }
        else {
          dienstjarenPerSchijf[i] = datumUitDienst.diff(beginDatum, 'years', true);
          break;
        }
      }

      // Afrondingscorrectie
      const afgerondeDienstjarenPerSchijf = dienstjarenPerSchijf.map((jaren) => Math.round(jaren));
      const afgerondeDienstjaren = afgerondeDienstjarenPerSchijf.reduce((total, jaren) => total + jaren, 0);

      if (afgerondeDienstjaren > dienstjaren) {
        let verschil = afgerondeDienstjaren - dienstjaren
        for (let i = 0; i < afgerondeDienstjarenPerSchijf.length; i++) {
          if (afgerondeDienstjarenPerSchijf[i] >= verschil) {
            afgerondeDienstjarenPerSchijf[i] -= verschil;
            break
          }
          else {
            verschil -= afgerondeDienstjarenPerSchijf[i];
            afgerondeDienstjarenPerSchijf[i] = 0;
          }
        }
      }

      return afgerondeDienstjarenPerSchijf.reduce((total, jaren, i) => total + (weights[i].ratio * jaren), 0);
    },

    get transitieVergoeding() {
      // *** EXCEPTION ***
      // Check if beeindigingHoudtVerbandMetSlechteFinancienWerkgever
      // If true, all the years of employment before 01-05-2013 are left out
      // We have to do this before calculating the months in service as this
      // mutation will otherwise not be taken into account
      const verbandSlechteFinancien = self.werkgever.verbandSlechteFinancien === 'correct';
      let datumInDienst = moment(self.algemeen.datumInDienst);
      if (self.werkgever.werkgeverHeeft25MeerWerknemers === 'incorrect' && verbandSlechteFinancien) {
        datumInDienst = moment("01-05-2013", "DD-MM-YYYY");
      }
      let datumUitDienst = moment(self.algemeen.datumUitDienst);
      // Get the amount of months in service
      const monthsInService = Math.abs(datumInDienst.diff(datumUitDienst, "months"));

      // Check if employee has had more than 2 years of service
      // else not eligible for transitievergoeding
      if (monthsInService <= 24) {
        return 0;
      }

      // // *** EXCEPTION ***
      // // Everything after 1 jan 2020 is not taken into account
      // // The days before are calculated per day

      // // Clone the dates we need since we might have to change
      // // them for the calculation
      // const datumUitDienst = self.algemeen.datumUitDienst.clone();
      // const datumInDienst = self.algemeen.datumInDienst.clone();
      // const firstOfJanuary = moment("01-01-2020", "DD-MM-YYYY");
      // var onbetaaldeDagen;

      // if (datumUitDienst.isAfter(firstOfJanuary)) {
      //   // This is the total amount of months worked from datumInDienst
      //   // until the 01-01-2020
      //   const aantalMaandenTransitieVergoedingRecht = Math.abs(
      //     firstOfJanuary.diff(datumInDienst, "months")
      //   );

      //   // This is the end date of the last 6 month period over which
      //   // the employee will get transitie vergoeding
      //   // We use this to calculate the remaining days until 01-01-2020
      //   const datumEindLaatsteHalfJaar = datumInDienst.add(
      //     aantalMaandenTransitieVergoedingRecht -
      //       (aantalMaandenTransitieVergoedingRecht % 6),
      //     "months"
      //   );

      //   // Calculate the amount of days in between the end date of
      //   // the last 6 month period
      //   onbetaaldeDagen = Math.abs(
      //     firstOfJanuary.diff(datumEindLaatsteHalfJaar, "days")
      //   );

      //   // This is the amount of months (always a multiple of 6)
      //   // to which we will apply the transitie vergoeding later
      //   monthsInService = Math.abs(
      //     datumEindLaatsteHalfJaar.diff(self.algemeen.datumInDienst, "months")
      //   );
      // }

      // *** EXCEPTION ***
      // Check if employee > 50 years old && >= 10 years service && > 25 employers

      const halveJaren = Math.floor(monthsInService / 6);
      let beginDatum = datumInDienst.clone();

      const brutoMaandSalaris = this.brutoMaandSalaris;
      let transitievergoeding = 0;

      for (let m = 1; m <= halveJaren; m++) {
        let ratio = 1 / 6;
        if (m > 20) {
          ratio = 1 / 4;

          const geboortedatum = moment(self.algemeen.geboortedatum);
          const leeftijd = beginDatum.diff(geboortedatum, 'years');
          if (self.werkgever.werkgeverHeeft25MeerWerknemers === 'correct' && leeftijd >= 50) {
            ratio = 1 / 2;
          }
        }

        transitievergoeding += ratio * brutoMaandSalaris;

        beginDatum.add(6, 'months');
      }
       

      // *** EXCEPTION ***
      // Check if the brutosalaris is higher than 81000
      // else if brutosalaris > 81000 use brutosalaris as maximum
      const brutoSalaris = self.looncomponenten.brutoSalaris * 12;
      if (transitievergoeding >= brutoSalaris && brutoSalaris >= 81000) {
        transitievergoeding = brutoSalaris;
      } else if (transitievergoeding >= 81000 && brutoSalaris <= 81000) {
        transitievergoeding = 81000;
      }

      return Math.round(transitievergoeding * 100) / 100;
    }
  }))
  .actions(self => (({
  })));

// restrict functions from being exposed, use as the following:
/*
 .actions(self => {
    const test = () => {
      test2();
    };

    const test2 = () => {
      // function test2
    };

    return { test }; // only the function test is exposed
  });
*/
