import * as React from 'react';
import { observer } from 'mobx-react';
import FormStaticAddon from './FormStaticAddon';

class FormInput extends React.Component {
  render() {
    const { type, field, horizontal, invalid, errorMsg, disabled, value, addonLeft } = this.props;
    if (horizontal) {
      return (
        <div className="field-body">
          {typeof addonLeft === 'undefined' ? '' :
            <FormStaticAddon>{{ __html: addonLeft }}</FormStaticAddon>
          }
          <div className={`field is-expanded`}>
            <input type={type} className={`input${invalid ? ' is-danger' : ''}`} {...field.inputProps} disabled={disabled} defaultValue={value} />
            {!invalid ? '' :
              <p className="help is-danger">{errorMsg}</p>
            }
          </div>
        </div>
      );
    }

    return (
      <>
        <div class="control">
          <input type={type} {...field.inputProps} disabled={disabled} />
        </div>
        {!invalid ? '' :
          <p className="help is-danger">{errorMsg}</p>
        }
      </>
    );
  }
}

export default observer(FormInput);
