import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from "mobx-react";
import { Field, Form, converters } from "mstform";
//import { postData } from '../../utils/Ajax';
import CalculatorTabMenu from '../../components/tabmenu/CalculatorTabMenu';
import Model from '../../store/brutonetto/boxeen';

import { FormInput, FormSelect, FormField, FormLabel } from '../../components/form/Form';

export default withRouter(inject("shop")(observer(
  class extends Component {
    constructor(props) {
      super(props);
      const requiredObject = {
        required: true,
        requiredError: context => 'Dit veld is verplicht'
      };

      const form = new Form(Model, { 
        //Arbeidsinkomen
        arbeidsinkomen: new Field(converters.number, requiredObject),
        extraterritorialeVergoedingen: new Field(converters.number, requiredObject),
        extraInvoerveld1: new Field(converters.number, requiredObject),
        extraInvoerveld2: new Field(converters.number, requiredObject),
        arbeidsinkomenBuitenland: new Field(converters.number, requiredObject),
        inkomstenbelastingBuitenland: new Field(converters.number, requiredObject),
        onbelasteVergoedingen: new Field(converters.number, requiredObject),
        reiskostenvergoeding: new Field(converters.number, requiredObject),

        //Huisvesting werkgever
        huurvergoedingWerkgever: new Field(converters.string, requiredObject),
        totaleHuur: new Field(converters.number, requiredObject),
        eigenBijdrageHuur: new Field(converters.number, requiredObject),
        achttienProcentHuisvestingRegeling: new Field(converters.string, requiredObject),

        //Pensioen
        pensioenpremieWerknemerEuro: new Field(converters.number, requiredObject),
        pensioenplanKwalificerend: new Field(converters.string, requiredObject),
        franchise: new Field(converters.number, requiredObject),
        pensioenpremieWerknemerPercentage: new Field(converters.number, requiredObject),
        pensioenpremieWerkgever: new Field(converters.number, requiredObject),
        pensioenBuitenlandPercentage: new Field(converters.number, requiredObject),

        //Auto vd zaak
        cataloguswaarde: new Field(converters.number, requiredObject),
        bijtellingPercentage: new Field(converters.number, requiredObject),
        eigenBijdrageAuto: new Field(converters.number, requiredObject),
        totaleKostenWerkgever: new Field(converters.number, requiredObject),
        bijtellingBuitenlandPercentage: new Field(converters.number, requiredObject),

        //Eigen woning
        wozWaardeWoning: new Field(converters.number, requiredObject),
        hypotheekrente: new Field(converters.number, requiredObject),
        andereAftrekposten: new Field(converters.number, requiredObject),
        hypotheekBeginperiode: new Field(converters.string, requiredObject),
        hypotheekEindperiode: new Field(converters.string, requiredObject),

        //Overig Box 1 inkomen / (Persoonsgebonden) aftrekposten
        overigBoxEen: new Field(converters.number, requiredObject),
        overigeAftrekposten: new Field(converters.number, requiredObject),
        nettoVergoeding: new Field(converters.number, requiredObject)
      });
      this.formState = form.state(props.shop.brutonetto.boxeen);
    }

    async onSubmit(e) {
      e.preventDefault();
      //const success = await this.formState.save();
      const success = this.formState.isValid;
      if (success) {
        this.props.goNext('boxone');
      }
    }

    //async process(node, path) {
    //  const prop = path.replace('/', '');
    //  return postData('/api/validation/validateSingle', {
    //    item: prop,
    //    value: node[prop]
    //  });
    //}

    //async save(node) {
    //  return postData('/api/validation/validate', node.toJSON());
    //}

    async onTabClick(clickedTabUrl) {
      //const success = await this.formState.save();
      const success = this.formState.isValid;
      if (success) {
        this.props.onTabClick(clickedTabUrl)
      }
    }

    render() {
      return (
        <div className="container">
          <CalculatorTabMenu onTabClick={this.onTabClick.bind(this)} />
          <section className="section">
            <form onSubmit={(e) => this.onSubmit(e)}>
              <section className="section">
                <h2 className="title is-size-3">Inkomen</h2>

                <FormField horizontal={true} field={this.formState.field("arbeidsinkomen")}>
                  <FormLabel>Nederlands arbeidsinkomen</FormLabel>
                  <FormInput type="text" />
                </FormField>
              
                <FormField horizontal={true} field={this.formState.field("extraterritorialeVergoedingen")}>
                  <FormLabel>Extraterritoriale vergoedingen</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("extraInvoerveld1")}>
                  <FormLabel>Extra invoerveld 1</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("extraInvoerveld2")}>
                  <FormLabel>Extra invoerveld 2</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("arbeidsinkomenBuitenland")}>
                  <FormLabel>Buitenlands arbeidsinkomen</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("onbelasteVergoedingen")}>
                  <FormLabel>Onbelaste vergoedingen</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("reiskostenvergoeding")}>
                  <FormLabel>Reiskostenvergoeding</FormLabel>
                  <FormInput type="text" />
                </FormField> 
              </section>

              <section className="section">
                <h2 className="title is-size-3">Huisvesting werkgever</h2>

                <FormField horizontal={true} field={this.formState.field("huurvergoedingWerkgever")}>
                  <FormLabel>Werkgever vergoedt huur aan werknemer</FormLabel>
                  <FormSelect>
                    <option value="correct">Ja</option>
                    <option value="incorrect">Nee</option>
                  </FormSelect>
                </FormField>

                <FormField horizontal={true} field={this.formState.field("totaleHuur")}>
                  <FormLabel>Totale huur</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("eigenBijdrageHuur")}>
                  <FormLabel>Eigen Bijdrage Huur</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("achttienProcentHuisvestingRegeling")}>
                  <FormLabel>18% huisvesting regeling</FormLabel>
                  <FormSelect>
                    <option value="correct">Ja</option>
                    <option value="incorrect">Nee</option>
                  </FormSelect>
                </FormField>
              </section>

              <section className="section">
                <h2 className="title is-size-3">Pensioen</h2>

                <FormField horizontal={true} field={this.formState.field("pensioenpremieWerknemerEuro")}>
                  <FormLabel>Pensioenpremie werknemer</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("pensioenplanKwalificerend")}>
                  <FormLabel>Pensioenplan</FormLabel>
                  <FormSelect>
                    <option value="correct">Ja</option>
                    <option value="incorrect">Nee</option>
                  </FormSelect>
                </FormField>

                <FormField horizontal={true} field={this.formState.field("franchise")}>
                  <FormLabel>Franchise</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("pensioenpremieWerknemerPercentage")}>
                  <FormLabel>Pensioenpremie werknemer (%)</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("pensioenpremieWerkgever")}>
                  <FormLabel>Pensioenpremie werkgever</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("pensioenBuitenlandPercentage")}>
                  <FormLabel>Pensioen toerekenbaar aan het buitenland (%)</FormLabel>
                  <FormInput type="text" />
                </FormField>
              </section>

              <section className="section">
                <h2 className="title is-size-3">Auto van de zaak</h2>

                <FormField horizontal={true} field={this.formState.field("cataloguswaarde")}>
                  <FormLabel>Cataloguswaarde</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("bijtellingPercentage")}>
                  <FormLabel>Percentage bijtelling</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("eigenBijdrageAuto")}>
                  <FormLabel>Eigen bijdrage</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("bijtellingBuitenlandPercentage")}>
                  <FormLabel>Percentage voordeel prive toerekenbaar aan het buitenland</FormLabel>
                  <FormInput type="text" />
                </FormField>
                
                <FormField horizontal={true} field={this.formState.field("totaleKostenWerkgever")}>
                  <FormLabel>Totale kosten werkgever</FormLabel>
                  <FormInput type="text" />
                </FormField> 
              </section>

              <section className="section">
                <h2 className="title is-size-3">Eigen woning</h2>

                <FormField horizontal={true} field={this.formState.field("wozWaardeWoning")}>
                  <FormLabel>WOZ waarde woning</FormLabel>
                  <FormInput type="text" />
                </FormField>
                <FormField horizontal={true} field={this.formState.field("hypotheekrente")}>
                  <FormLabel>Hypotheekrente</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("andereAftrekposten")}>
                  <FormLabel>Andere aftrekposten</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("hypotheekBeginperiode")}>
                  <FormLabel>Begin periode</FormLabel>
                  <FormInput type="date" />
                </FormField>
                 
                <FormField horizontal={true} field={this.formState.field("hypotheekEindperiode")}>
                  <FormLabel>Eind periode</FormLabel>
                  <FormInput type="date" />
                </FormField> 
              </section>

              <section className="section">
                <h2 className="title is-size-3">Overig inkomen</h2>

                <FormField horizontal={true} field={this.formState.field("overigBoxEen")}>
                  <FormLabel>Overig Box 1 inkomen</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("overigeAftrekposten")}>
                  <FormLabel>Overige aftrekposten</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("nettoVergoeding")}>
                  <FormLabel>Netto vergoeding/verstrekking</FormLabel>
                  <FormInput type="text" />
                </FormField> 
              </section>

              <div className="field is-horizontal">
                <div className="field-label"></div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button className="button is-info" onClick={(e) => this.props.goPrevious('boxone')}>Vorige</button>
                      <button type="submit" className="button is-primary">Volgende</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <p className="is-size-7">Deze tool is uitsluitend opgesteld als hulpmiddel. Het dient geen specifiek doel en dient niet te worden geïnterpreteerd als professioneel advies. Gebruik ervan is voor eigen rekening en risico. U dient niet te handelen op basis van de in deze tool vervatte informatie zonder nader professioneel advies te hebben ingewonnen. Er wordt geen enkele expliciete of impliciete verklaring verstrekt of garantie geboden over de juistheid of volledigheid van de in deze tool vervatte informatie, en voor zover toegestaan krachtens de wet, aanvaarden PwC, aan haar gelieerde ondernemingen, medewerkers en vertegenwoordigers geen enkele aansprakelijkheid, en wijzen zij iedere verantwoordelijkheid af, voor de gevolgen van enige handeling dan wel omissie door hetzij uzelf hetzij enige andere persoon op basis van de in deze tool vervatte informatie of voor enig besluit waaraan die informatie ten grondslag ligt. PwC heeft de eigendomsrechten op deze tool. Gebruiker is het volgende niet toegestaan, noch mag gebruiker een derde machtigen het volgende te doen met betrekking tot deze tool en/of de inhoud daarvan: (a) kopiëren of verspreiden, (b) aanpassen of een afgeleide creëren, (c) de broncode afleiden, veranderen, bewerken etc. (d) distribueren, exploiteren, overdragen, sublicenseren van deze tool of daaraan gerelateerde rechten.</p>
        </div>
      );
    }
  }
)));
