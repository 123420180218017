import { types } from "mobx-state-tree";

export default types.model({
  //Box 2
  aanmerkelijkBelang: types.optional(types.number, 0),
  aanmerkelijkBelangBuitenland: types.optional(types.number, 0),

  //Box 3
  vermogen: types.optional(types.number, 0),
  vermogenBuitenland: types.optional(types.number, 0),
  vermogenHeffingvrij: types.optional(types.number, 30000)
});
