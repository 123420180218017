import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from "mobx-react";
import { Field, Form, converters } from "mstform";
//import { postData } from '../../utils/Ajax';
import CalculatorTabMenu from '../../components/tabmenu/CalculatorTabMenu';
import Model from '../../store/brutonetto/boxtweedrie';

import { FormInput, FormSelect, FormField, FormLabel } from '../../components/form/Form';

export default withRouter(inject("shop")(observer(
  class extends Component {
    constructor(props) {
      super(props);

      const requiredObject = {
        required: true,
        requiredError: context => 'Dit veld is verplicht'
      };

      const form = new Form(Model, {
        aanmerkelijkBelang: new Field(converters.number, requiredObject),
        aanmerkelijkBelangBuitenland: new Field(converters.number, requiredObject),
        vermogen: new Field(converters.number, requiredObject),
        vermogenBuitenland: new Field(converters.number, requiredObject),
        vermogenHeffingvrij: new Field(converters.number, requiredObject)
      });

      this.formState = form.state(props.shop.brutonetto.boxtweedrie);
    }

    async onSubmit(e) {
      e.preventDefault();
      //const success = await this.formState.save();
      const success = this.formState.isValid;
      if (success) {
        this.props.goNext('boxone');
      }
    }

    //async process(node, path) {
    //  const prop = path.replace('/', '');
    //  return postData('/api/validation/validateSingle', {
    //    item: prop,
    //    value: node[prop]
    //  });
    //}

    //async save(node) {
    //  return postData('/api/validation/validate', node.toJSON());
    //}

    async onTabClick(clickedTabUrl) {
      //const success = await this.formState.save();
      const success = this.formState.isValid;
      if (success) {
        this.props.onTabClick(clickedTabUrl);
      }
    }

    render() {      
      return (
        <div className="container">
          <CalculatorTabMenu onTabClick={this.onTabClick.bind(this)} />
          <section className="section">
            <form onSubmit={(e) => this.onSubmit(e)}>
              <section className="section">
                <h2 className="title is-size-3">Box 2</h2>

                <FormField horizontal={true} field={this.formState.field("aanmerkelijkBelang")}>
                  <FormLabel>Aanmerkelijk belang</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("aanmerkelijkBelangBuitenland")}>
                  <FormLabel>Buitenlands aanmerkelijk belang</FormLabel>
                  <FormInput type="text" />
                </FormField> 
              </section>
              <section className="section">
                <h2 className="title is-size-3">Box 3</h2>

                <FormField horizontal={true} field={this.formState.field("vermogen")}>
                  <FormLabel>Nederlands vermogen</FormLabel>
                  <FormInput type="text" />
                </FormField>

                <FormField horizontal={true} field={this.formState.field("vermogenBuitenland")}>
                  <FormLabel>Buitenlands vermogen</FormLabel>
                  <FormInput type="text" />
                </FormField>
                 
                <FormField horizontal={true} field={this.formState.field("vermogenHeffingvrij")}>
                  <FormLabel>Heffingsvrij vermogen</FormLabel>
                  <FormSelect>
                    <option value="30000">€30.000,- (geen partner)</option>
                    <option value="60000">€60.000,- (partner)</option>
                  </FormSelect>
                </FormField>
                 
              </section>
              <div className="field is-horizontal">
                <div className="field-label"></div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button className="button is-info" onClick={(e) => this.props.goPrevious('boxtwothree')}>Vorige</button>
                      <button type="submit" className="button is-primary">Volgende</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <p className="is-size-7">Deze tool is uitsluitend opgesteld als hulpmiddel. Het dient geen specifiek doel en dient niet te worden geïnterpreteerd als professioneel advies. Gebruik ervan is voor eigen rekening en risico. U dient niet te handelen op basis van de in deze tool vervatte informatie zonder nader professioneel advies te hebben ingewonnen. Er wordt geen enkele expliciete of impliciete verklaring verstrekt of garantie geboden over de juistheid of volledigheid van de in deze tool vervatte informatie, en voor zover toegestaan krachtens de wet, aanvaarden PwC, aan haar gelieerde ondernemingen, medewerkers en vertegenwoordigers geen enkele aansprakelijkheid, en wijzen zij iedere verantwoordelijkheid af, voor de gevolgen van enige handeling dan wel omissie door hetzij uzelf hetzij enige andere persoon op basis van de in deze tool vervatte informatie of voor enig besluit waaraan die informatie ten grondslag ligt. PwC heeft de eigendomsrechten op deze tool. Gebruiker is het volgende niet toegestaan, noch mag gebruiker een derde machtigen het volgende te doen met betrekking tot deze tool en/of de inhoud daarvan: (a) kopiëren of verspreiden, (b) aanpassen of een afgeleide creëren, (c) de broncode afleiden, veranderen, bewerken etc. (d) distribueren, exploiteren, overdragen, sublicenseren van deze tool of daaraan gerelateerde rechten.</p>
        </div>
      );
    }
  }
)));
