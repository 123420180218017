import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router';
import Home from '../pages/Home';
import { FetchData } from './FetchData';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import BrutoNetto from '../pages/BrutoNetto';
import TransitieVergoeding from "../pages/TransitieVergoeding";
import BelastingRente from '../pages/BelastingRente';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

class RouteView extends React.Component {
  render() {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Route exact path="/" component={Home} />

          <Route exact path="/brutonetto" render={() => (
            <Redirect to='/brutonetto/start' />
          )} />
          <Route exact path='/brutonetto/:page' component={BrutoNetto} />

          <Route exact path="/transitievergoeding" render={() => (
            <Redirect to='/transitievergoeding/algemeen' />
          )} />
          <Route exact path='/transitievergoeding/:page' component={TransitieVergoeding} />

          <Route exact path="/belastingrente" render={() => (
            <Redirect to='/belastingrente/algemeen' />
          )} />
          <Route exact path='/belastingrente/:page' component={BelastingRente} />

          <AuthorizeRoute path='/fetch-data' component={FetchData} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        </Suspense>
      </>
    );
  }
}

export default RouteView;
