import * as React from 'react';
import { observer } from 'mobx-react';

export default observer(class extends React.Component {

  render() {
    const { children } = this.props;

    if (typeof children === 'object') {
      return (
        <div className="control">
          <span className="button is-static">{children}</span>
        </div>
      );
    }

    return (
      <div className="control"> {}
        <span className="button is-static" dangerouslySetInnerHTML={{ __html: children }} />
      </div>
    );
  }
});
