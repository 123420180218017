import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Icon extends React.Component { 
  render() {
    const { category, fa, className } = this.props;

    return (
      <FontAwesomeIcon icon={[category, fa]} className={className} {...this.props}/>
    );
  }
}

Icon.defaultProps = {
  className: '',
  category: 'fas'
};

export default Icon;
