import { types } from "mobx-state-tree";

export default types.model({
  combinatiekorting: types.optional(types.string, 'incorrect'),
  aanvullendeOuderenkorting: types.optional(types.string, 'incorrect'),
  overdrachtPartner: types.optional(types.string, 'incorrect'),
  afbouwregelingPartner: types.optional(types.string, 'incorrect'),
  levensloopkorting: types.optional(types.string, 'incorrect'),
  levensloopkortingJaren: types.optional(types.number, 0)
});
