import * as React from 'react';
import { observer } from 'mobx-react'

class FormField extends React.Component {
  render() {
    let { children, field, horizontal, multiField } = this.props;
    if (typeof field === "undefined") {
      field = { inputProps: {} }
    }
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        horizontal,
        field,
        invalid: typeof field.error !== 'undefined' && field.error.length > 0,
        errorMsg: field.error,
        multiField
      })
    );

    multiField = typeof multiField !== 'undefined' && multiField;

    return (
      <div className={`field${horizontal ? ' is-horizontal' : ''}${multiField ? '' : ' has-addons'}`}>
        {childrenWithProps}
        {/*horizontal ? '' :
          <p className="help is-danger">{field.error}</p>
        */}
      </div>
    );
  }
}
export default observer(FormField);
