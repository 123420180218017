import { types } from "mobx-state-tree";
import { addMonths, addDays, max } from "date-fns";

import Algemeen from './algemeen';
import Aangifte from './aangifte';
import VoorlopigeAanslagen from './voorlopigeaanslagen';
import UiteindelijkeAanslag from './uiteindelijkeaanslag';

import percentagesData from './percentages.json';

export default types
  .model({
    algemeen: types.optional(Algemeen, {}),
    aangifte: types.optional(Aangifte, {}),
    voorlopigeaanslagen: types.optional(VoorlopigeAanslagen, {}),
    uiteindelijkeaanslag: types.optional(UiteindelijkeAanslag, {})
  })
  .views(self => ({
    get percentages() {
      let res = {};
      for (let type in percentagesData) {
        for (let year in percentagesData[type]) {
          for (let i = 0; i < percentagesData[type][year].length; i++) {
            res[type + year + "-" + i] = percentagesData[type][year][i];
          }
        }
      }
      return res;
    },

    get resultaat() { 
      // Initialize variables
      const type = self.algemeen.soort === 2 ? "vpb" : "overig";
      let afwijking;
      let aangifteOnTime = false;
      let aanslagOnTime = false;

      let aangifteDate = new Date(self.aangifte.datumAangifte);
      let aanslagDate;
      // if(formData.countTussentijds > 0) {
      //   aanslagDate = parseDateString(formData.datumTussentijds1);
      //   afwijking = formData.aangifteBedrag != formData.bedragTussentijds1;
      // }
      // else{
      aanslagDate = new Date(self.uiteindelijkeaanslag.datumAanslag);
      afwijking = self.aangifte.somBelasting !== self.uiteindelijkeaanslag.somBelasting;
      // }
      let startDate;
      let endDate;
      let tussentijdsArray = [];
      let tussentijdsLength = 0;
      if (self.voorlopigeaanslagen.aanslagen.length > 0) {
        tussentijdsLength = self.voorlopigeaanslagen.aanslagen.length;

        tussentijdsArray = self.voorlopigeaanslagen.aanslagen.slice(0);
        tussentijdsArray.sort(function (a, b) {
          let aDate = new Date(a.datumBetaling);
          let bDate = new Date(b.datumBetaling);
          if (aDate < bDate) {
            return -1;
          }
          if (aDate > bDate) {
            return 1;
          }
          return 0;
        });
      }

      // Prevent calculations when dates are invalid
      if (self.aangifte.aangifteGedaan === 'correct' && !this.validateTimePeriod(aangifteDate, aanslagDate)) {
        return 0;
      }

      let boekjaarDate = self.algemeen.gebrokenBoekjaar === 'correct' ? new Date(self.algemeen.gebrokenBoekjaar) : new Date(`${self.algemeen.boekjaar + 1}-01-01`);
       
      [startDate, endDate, aangifteOnTime, aanslagOnTime] = this.processDates(self.algemeen.gebrokenBoekjaar === 'correct', afwijking, boekjaarDate, self.aangifte.aangifteGedaan === 'correct', aangifteDate, aanslagDate, parseInt(self.algemeen.geldstroom));
      console.log("Processed Dates:", startDate, endDate, aangifteOnTime, aanslagOnTime);

      let renteBedrag = Math.abs(self.uiteindelijkeaanslag.somBelasting);
      let result = 0;

      let belastingRente = false;

      if (self.algemeen.geldstroom === '1') {
        // console.log("Geldstroom: Te betalen");
        // belastingRente = !aanslagOnTime && (afwijking || !aangifteOnTime);
        belastingRente = !((aangifteOnTime && !afwijking) || (afwijking && aanslagOnTime));
      }
      else {
        // console.log("Geldstroom: Teruggave");
        belastingRente = !afwijking && !aanslagOnTime;
        renteBedrag = -Math.abs(renteBedrag);
      }


      if (belastingRente) {
        let percentages = this.percentages;
        // console.log("Got Percentages");
        let currentBedrag;
        let currentDate; 
        //Tussentijdse aangiftes
        for (let i = 0; i < tussentijdsLength; i++) {
          currentBedrag = renteBedrag - tussentijdsArray[i].som;
          currentDate = new Date(tussentijdsArray[i].datumBetaling);

          if (currentDate < endDate) {
            if (currentDate > startDate) {
              let tempResult = this.calculateInterest(percentages, startDate, currentDate, currentBedrag, type);
              result += tempResult;
            }
            startDate = addDays(currentDate, 1);
          }
        }

        console.log("Calculating interest:", startDate, endDate, renteBedrag, type);
        let tempResult = 0;
        if (startDate instanceof Date && !isNaN(startDate) && endDate instanceof Date && !isNaN(endDate)) {
          tempResult = this.calculateInterest(percentages, startDate, endDate, renteBedrag, type);
        }
        result += tempResult;
        if (result === null) {
          console.log("Invalid calculation");
          result = 0;
        }
      }

      return result;
    },

    calculateInterest(interestData, startDate, endDate, amount, type) {
      let interest = this.getInterest(interestData, type, startDate.getFullYear(), startDate.getMonth());

      if (interest === null) {
        console.log("Error getting interest");
        return null;
      }

      let days, result;

      if (startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth()) {
        days = Math.min(endDate.getDate() - startDate.getDate() + 1, 30);
        result = days / 360 * amount * interest;

        return result;
      }
      else {
        days = Math.min(Math.max(1, 31 - startDate.getDate()), 30);
        result = days / 360 * amount * interest;

        let newStartDate = new Date(startDate);
        newStartDate.setDate(1);
        newStartDate.setMonth(newStartDate.getMonth() + 1);

        return result + this.calculateInterest(interestData, newStartDate, endDate, amount, type);
      }
    },

    getInterest(interestData, type, year, month) {
      console.log('getinterest', type, year, month);
      // console.log("Getting interest", type, year, month);
      // console.log(data[type+year+"-"+month]);
      if (interestData[type + year + "-" + month] === null) {
        console.log("Month out of range:", year, month);
        return null;
      }
      return parseFloat(interestData[type + year + "-" + month]);
    },

    validateTimePeriod(date1, date2) {
      if (date1 === null || date2 === null) {
        console.log("Invalid dates");
        return false;
      }
      if (date1 > date2) {
        console.log("Backwards dates");
        return false;
      }
      return true;
    },

    processDates(afwijkendBoekjaar, afwijkendBedrag, boekjaar, aangifte, aangifteDatum, aanslagDatum, geldstroom) {
      let startDate;
      let endDate;
      let aangifteOnTime = false;
      let aanslagOnTime = false;

      if (boekjaar === null) {
        console.log("Invalid boekjaar");
        return null;
      }

      if (afwijkendBoekjaar) {
        let boekjaar6months;
        if (([30, 31].includes(boekjaar.getDate())) || (boekjaar.getMonth === 1 && [28, 29].includes(boekjaar.getDate()))) {
          boekjaar6months = addMonths(addMonths(boekjaar, 1).setDate(1), 6);
        }
        else {
          boekjaar6months = addMonths(addDays(boekjaar, 1), 6);
        }
        console.log(boekjaar, boekjaar6months);
        // console.log(aangifteDatum, addMonths(boekjaar, 3));
        if (aangifte && aangifteDatum < addMonths(addDays(boekjaar, 1), 3)) {
          aangifteOnTime = true;
        }

        if (aanslagDatum < boekjaar6months) {
          aanslagOnTime = true;
        }
        if (geldstroom === 1) {
          startDate = boekjaar6months;
        }
        else {
          // if(aangifteOnTime)
          // {
          //   startDate = addMonths(aangifteDatum, 0, 3);
          // }
          // else{
          startDate = max(addDays(aangifteDatum, 91), boekjaar6months);
          // }
          // startDate = addMonths(addDays(aangifteDatum, 1), 3)
        }
      }
      else {
        let boekjaar6months = addMonths(boekjaar, 6);
        if (aangifte && aangifteDatum < addMonths(boekjaar, 4)) {
          aangifteOnTime = true;
        }
        if (aanslagDatum < boekjaar6months || (geldstroom === 2 && aanslagDatum < addDays(aangifteDatum, 91))) {
          aanslagOnTime = true;
        }
        if (geldstroom === 2) {
          // if(aangifteOnTime)
          // {
          // startDate = dates.incrementDate(aangifteDatum, 0, 3);
          // }
          // else{
          startDate = max(addDays(aangifteDatum, 91), boekjaar6months);
          // }
          // startDate = addMonths(addDays(aangifteDatum, 1), 3)
        }
        else {
          startDate = boekjaar6months;
        }
      }

      // if(afwijkendBoekjaar === 'true'){
      //   console.log("Gebroken Boekjaar");
      //
      // }
      console.log('aanslagDatum', aanslagDatum);
      if (geldstroom === 2) {
        endDate = addDays(aanslagDatum, 42);
      }
      else {
        // console.log("Regulier Boekjaar");
        if (aanslagDatum > addDays(aangifteDatum, 91) && !afwijkendBedrag) {
          endDate = addDays(aangifteDatum, 133);
        }
        else {
          endDate = addDays(aanslagDatum, 42);
        }
      }

      return [startDate, endDate, aangifteOnTime, aanslagOnTime];
    }
  }))
  .actions(self => (({
  }))); 
