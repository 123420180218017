import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

export default withTranslation()(withRouter(
  class extends React.Component {
    render() {
      const { t } = this.props;
      return (
        <>
          <section className="hero is-danger">
            <div className="hero-body">
              <div className="container">
                <h2 className="title">Home</h2>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="title">Hello World</p>
                  <p className="subtitle">What is up?</p>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="title">Hallo Wereld</p>
                  <p className="subtitle">Wat is boven?</p>
                  <p>{t('werkgevernaamtekort')}</p>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="title">Third column</p>
                  <p className="subtitle">With some content</p>
                  <div className="content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare magna eros, eu pellentesque tortor vestibulum ut. Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.</p>
                  </div>
                </article>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
));
