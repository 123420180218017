import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; 

import Algemeen from "./transitievergoeding/algemeen";
import LoonComponenten from "./transitievergoeding/looncomponenten";
import Werkgever from "./transitievergoeding/werkgever";
import Uitkomst from "./transitievergoeding/uitkomst";

export default withRouter(class extends Component {
  getSubPage() {
    const page = typeof this.props.match.params.page === 'undefined' ? '' : this.props.match.params.page;
    const pageProps = {
      goNext: this.goNext.bind(this),
      goPrevious: this.goPrevious.bind(this),
      onTabClick: this.onTabClick.bind(this)
    };

    switch (page.toLowerCase()) {
      case "looncomponenten": return <LoonComponenten {...pageProps} />;
      case "werkgever": return <Werkgever {...pageProps} />;
      case "uitkomst": return <Uitkomst {...pageProps} />;
      default: return <Algemeen {...pageProps} />;
    }
  }

  onTabClick(clickedTabUrl) {
    this.goTo(clickedTabUrl);
  }

  goNext(currentTab) {
    let locations = ['algemeen', 'looncomponenten', 'werkgever', 'uitkomst'];
    let nextLocation = locations[0];
    let index = locations.indexOf(currentTab);
    if (index + 1 < locations.length) {
      nextLocation = locations[index + 1];
    }
    this.goTo(nextLocation);
  }

  goPrevious(currentTab) {
    let locations = ['algemeen', 'looncomponenten', 'werkgever', 'uitkomst'];
    let nextLocation = locations[0];
    let index = locations.indexOf(currentTab);
    if (index > 0) {
      nextLocation = locations[index - 1];
    }
    this.goTo(nextLocation);
  }

  goTo(page) {
    this.props.history.replace(this.props.match.path.replace(':page', page));
  }

  render() {
    return (
      <>
        <section className="hero is-danger">
          <div className="hero-body">
            <div className="container">
              <h2 className="title">Transitievergoeding Calculator</h2>
            </div>
          </div>
        </section>
        <section className="section">
          {this.getSubPage()}
        </section>
      </>
    );
  }
});
