import React, { Component } from 'react'; 
import { NavMenu } from './components/NavMenu';
import RouteView from './components/RouteView';
import './utils/FontAwesome';
import './i18n';

class App extends Component {
  render() {
    return (
      <> 
        <NavMenu></NavMenu>
        <RouteView></RouteView>
      </>
    );
  }
}

export default App;
