import { types } from "mobx-state-tree";

export default types.model({
  //Arbeidsinkomen
  arbeidsinkomen: types.optional(types.number, 0),
  extraterritorialeVergoedingen: types.optional(types.number, 0),
  extraInvoerveld1: types.optional(types.number, 0),
  extraInvoerveld2: types.optional(types.number, 0),
  arbeidsinkomenBuitenland: types.optional(types.number, 0),
  inkomstenbelastingBuitenland: types.optional(types.number, 0),
  onbelasteVergoedingen: types.optional(types.number, 0),
  reiskostenvergoeding: types.optional(types.number, 0),

  //Huisvesting werkgever
  huurvergoedingWerkgever: types.optional(types.string, 'correct'),
  totaleHuur: types.optional(types.number, 0),
  eigenBijdrageHuur: types.optional(types.number, 0),
  achttienProcentHuisvestingRegeling: types.optional(types.string, 'incorrect'),

  //Pensioen
  pensioenpremieWerknemerEuro: types.optional(types.number, 0),
  pensioenplanKwalificerend: types.optional(types.string, 'correct'),
  franchise: types.optional(types.number, 0),
  pensioenpremieWerknemerPercentage: types.optional(types.number, 0),
  pensioenpremieWerkgever: types.optional(types.number, 0),
  pensioenBuitenlandPercentage: types.optional(types.number, 0),

  //Auto vd zaak
  cataloguswaarde: types.optional(types.number, 0),
  bijtellingPercentage: types.optional(types.number, 0),
  eigenBijdrageAuto: types.optional(types.number, 0),
  totaleKostenWerkgever: types.optional(types.number, 0),
  bijtellingBuitenlandPercentage: types.optional(types.number, 0),

  //Eigen woning
  wozWaardeWoning: types.optional(types.number, 0),
  hypotheekrente: types.optional(types.number, 0),
  andereAftrekposten: types.optional(types.number, 0),
  hypotheekBeginperiode: types.optional(types.string, '2018-01-01'),
  hypotheekEindperiode: types.optional(types.string, '2018-12-31'),

  //Overig Box 1 inkomen / (Persoonsgebonden) aftrekposten
  overigBoxEen: types.optional(types.number, 0),
  overigeAftrekposten: types.optional(types.number, 0),
  nettoVergoeding: types.optional(types.number, 0)
});
