import i18n from 'i18next';
import Fetch from 'i18next-fetch-backend';
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n.languages = ['nl', 'en'];

i18n
  .use(Fetch)
  .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: '/api/locale/getTranslations?lng={{lng}}'
    },
    cache: {
      enabled: true,
      expirationTime: 86400
    },
    fallbackLng: 'nl',
    preload: ['nl', 'en'],
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false
    }
  });
 

export default i18n;
