import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from "mobx-react";
import { Field, Form, converters } from "mstform";
//import { postData } from '../../utils/Ajax';
import TabMenu from '../../components/tabmenu/TransitieVergoedingTabMenu';
import Model from '../../store/transitievergoeding/werkgever';

import { FormSelect, FormField, FormLabel } from '../../components/form/Form';

export default withRouter(inject("shop")(observer(
  class extends Component {
    constructor(props) {
      super(props);
      const requiredObject = {
        required: true,
        requiredError: context => 'Dit veld is verplicht'
      }
      const form = new Form(Model, {
        werkgeverHeeft25MeerWerknemers: new Field(converters.string, requiredObject),
        verbandSlechteFinancien: new Field(converters.string, requiredObject)
      });
      this.formState = form.state(props.shop.transitievergoeding.werkgever, {
        // backend validation
        //backend: { save: this.save.bind(this), process: this.process.bind(this) }
      });
    }

    async onSubmit(e) {
      e.preventDefault();
      //const success = await this.formState.save();
      const success = this.formState.isValid;
      if (success) {
        this.props.goNext('werkgever');
      }
    }

    //async process(node, path) {
    //  const prop = path.replace('/', '');
    //  return postData('/api/validation/validateSingle', {
    //    item: prop,
    //    value: node[prop]
    //  });
    //}

    //async save(node) {
    //  return postData('/api/validation/validate', node.toJSON());
    //}

    async onTabClick(clickedTabUrl) {
      //const success = await this.formState.save();
      const success = this.formState.isValid;
      if (success) {
        this.props.onTabClick(clickedTabUrl);
      }
    }

    render() {
      const { werkgeverHeeft25MeerWerknemers } = this.formState.node;

      return (
        <div className="container">
          <TabMenu onTabClick={this.onTabClick.bind(this)} />
          <section className="section">
            <form onSubmit={(e) => this.onSubmit(e)}>
              <FormField horizontal={true} field={this.formState.field("werkgeverHeeft25MeerWerknemers")}>
                <FormLabel>Werkgever heeft 25 of meer werknemers in dienst</FormLabel>
                <FormSelect>
                  <option value="correct">Ja</option>
                  <option value="incorrect">Nee</option>
                </FormSelect>
              </FormField>
              {werkgeverHeeft25MeerWerknemers === 'correct' ? '' :
                <FormField horizontal={true} field={this.formState.field("verbandSlechteFinancien")}>
                  <FormLabel>Beëindiging houdt verband met slechte financiële situatie van de werkgever</FormLabel>
                  <FormSelect>
                    <option value="correct">Ja</option>
                    <option value="incorrect">Nee</option>
                  </FormSelect>
                </FormField>
              }

              <div className="field is-horizontal">
                <div className="field-label"></div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button type="button" className="button is-info" onClick={(e) => this.props.goPrevious('werkgever')}>Vorige</button>
                      <button type="submit" className="button is-primary">Bekijk uitkomst</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <p className="is-size-7">Deze tool is uitsluitend opgesteld als hulpmiddel. Het dient geen specifiek doel en dient niet te worden geïnterpreteerd als professioneel advies. Gebruik ervan is voor eigen rekening en risico. U dient niet te handelen op basis van de in deze tool vervatte informatie zonder nader professioneel advies te hebben ingewonnen. Er wordt geen enkele expliciete of impliciete verklaring verstrekt of garantie geboden over de juistheid of volledigheid van de in deze tool vervatte informatie, en voor zover toegestaan krachtens de wet, aanvaarden PwC, aan haar gelieerde ondernemingen, medewerkers en vertegenwoordigers geen enkele aansprakelijkheid, en wijzen zij iedere verantwoordelijkheid af, voor de gevolgen van enige handeling dan wel omissie door hetzij uzelf hetzij enige andere persoon op basis van de in deze tool vervatte informatie of voor enig besluit waaraan die informatie ten grondslag ligt. PwC heeft de eigendomsrechten op deze tool. Gebruiker is het volgende niet toegestaan, noch mag gebruiker een derde machtigen het volgende te doen met betrekking tot deze tool en/of de inhoud daarvan: (a) kopiëren of verspreiden, (b) aanpassen of een afgeleide creëren, (c) de broncode afleiden, veranderen, bewerken etc. (d) distribueren, exploiteren, overdragen, sublicenseren van deze tool of daaraan gerelateerde rechten.</p>
        </div>
      );
    }
  }
)));
