import { types } from "mobx-state-tree";

const Aanslag = types.model("Aanslag", {
  somAanslag: types.optional(types.number, 0),
  datumAanslag: types.optional(types.string, ''),
  datumBetaling: types.optional(types.string, '')
});

export default types.model("VoorlopigeAanslagen", {
  aanslagen: types.optional(types.array(Aanslag), [])
})
  .actions(self => (({
    add() {
      self.aanslagen.push(Aanslag.create({}));
    },

    remove(index) {
      self.aanslagen.splice(index, 1);
    }
  })));

export { Aanslag };
