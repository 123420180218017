import * as React from 'react';
import { observer } from 'mobx-react';

class FormLabel extends React.Component {
  getLabel() {
    const { field, children } = this.props;
    return <label className="label" htmlFor={field.name}>{children}</label>;
  }

  render() {
    const { horizontal } = this.props;

    if (horizontal) {
      return (
        <div className="field-label">
          {this.getLabel()}
        </div>
      );
    }

    return this.getLabel();
  }
}

export default observer(FormLabel);
