import * as React from 'react'
import { withRouter } from 'react-router-dom';
import { observer, inject } from "mobx-react";

class CalculatorTabMenu extends React.Component {
  getTabs(data) {
    data.active = typeof data.active === 'undefined' || data === null ? data.tabs[0].match : data.active;
    return data.tabs.map(tab => {
      return (
        <li className={tab.match === data.active ? 'is-active' : ''} key={tab.match}>
          <a href={`#${tab.match}`} onClick={(e) => this.onTabClick(e, tab.match)}><span>{tab.title}</span></a>
        </li>
      );
    });
  }

  onTabClick(e, tabPageUrl) { 
    e.preventDefault();
    this.props.onTabClick(tabPageUrl);
  }

  render() {
    const active = typeof this.props.match.params.page === 'undefined' ? 'start' : this.props.match.params.page;
    return (
      <div className="tabs is-toggle is-medium is-fullwidth">
        <ul>
          {this.getTabs({
            tabs: [{
              title: 'Start',
              match: 'start'
            }, {
              title: 'Algemeen',
              match: 'general'
            }, {
              title: 'Box 1',
              match: 'boxone'
            }, {
              title: 'Box 2 & 3',
              match: 'boxtwothree'
            }, {
              title: 'Heffingskortingen',
              match: 'taxcredit'
            }, {
              title: 'Output',
              match: 'output'
            }],
            active
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(inject("shop")(observer(CalculatorTabMenu)));
